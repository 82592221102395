import React, { Component } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import axios from 'axios'
import { toast,ToastContainer } from 'react-toastify';
import '../pages.css';
import 'react-toastify/dist/ReactToastify.css';
import { toastOptions, emailValidation, phoneValidation, pinValidation } from '../../utils';
import SEOHelmet from '../../components/SEOHelmet';
import ProductCard from "../../components/productCard";
import Carousel from "react-multi-carousel";
import {  contact_carousel } from "../../utils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons';

const baseUrl = process.env.REACT_APP_API;


class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
        'name':'',
        'email':'',
        'phone':'',
        'pin' : '',
        'subject':'',
        'message':'',
        'content_array':[],
        spotlight:[],
        fixedHeader:false,
        seo:'',
    };
    this.nameRef = React.createRef();
    this.emailRef = React.createRef();
    this.phoneRef = React.createRef();
    this.pinRef = React.createRef();
    this.subjectRef = React.createRef();
    this.messageRef = React.createRef();
  }

  componentDidMount(){


    axios.get(`${baseUrl}/content/contact`).then((res) => {
      const content_array = res.data.content;
      const seo = res.data.seo[0];
      this.setState({ content_array });
      this.setState({ seo });
    })
    axios.get(`${baseUrl}/project/`).then((res) => {
      const projects = res.data.data;
      const spotlight = projects.filter(project=>project.spotlight == 1);
      this.setState({spotlight});
    });
  }
  
  scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      this.setState({fixedHeader:true});
    }else{
      this.setState({fixedHeader:false});
    }
  };

  onChange = (e) => {
    this.setState({
        [e.target.name]: e.target.value
    })
  }

  randomImage = (proj, array)=>{
    const random = Math.floor(Math.random() * array.length);
    let randomImg = array[array.length-1].image1;
    if(randomImg != ''){
        return <ProductCard application={proj.proj_type}  id={proj.id} image1={randomImg} title={proj.title} />
    }else{
        this.randomImage(proj, array);
    }
  }

  onSubmit = (e) => {    
    const {  name, email, phone, pin, message, subject,content_array } = this.state;
    const content = content_array[0].web_email;
    const data = { name, email, pin, phone, message, subject, content }

    let valid = name == '' ?  this.showToastError('Please enter name', this.nameRef ) 
              : email =='' ?  this.showToastError('Please enter email', this.emailRef )
              : !emailValidation(email) ?  this.showToastError('Enter valid email', this.emailRef ) 
              : phone == '' ? this.showToastError('Please enter phone', this.phoneRef ) 
              : !phoneValidation(phone) ?  this.showToastError('Enter valid phone number', this.phoneRef )
              : pin == '' ? this.showToastError('Please enter pin code', this.pinRef ) 
              : !pinValidation(pin) ? this.showToastError('Please enter valid pin code', this.pinRef ) 
              : subject == '' ? this.showToastError('Please enter subject', this.subjectRef ) 
              : message == '' ? this.showToastError('Please enter message', this.messageRef ) : true;
  
    if(valid){
      axios.post(`${baseUrl}/contact/`, data)
      .then((res) => {
          if (res.data.success== 1) {
            this.onCancel();
            this.props.navigate('/thank-you')
          } else {
          }
      })
    }
  }

  showToastError(message, ref){
    toast.error(message, toastOptions);
    ref.current.focus()
  }

  onCancel = () => {
    this.setState({
        name: '',
        phone: '',
        email: '',
        pin: '',
        subject:'',
        message: ''
    })
  }

  render() {
    const { name, email, phone, pin, subject, message, showToast , content_array, fixedHeader, seo, spotlight} = this.state;
    return (
      <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical='/contact' /> }
        <ToastContainer/>
        <div className='scroll-div site' id="page" onScroll={(e)=>this.scrollHandler(e)}>
        <Header type={fixedHeader}/>
            {/* <div id="custom-header" className="contact-header"> */}
                            {content_array.length>0 ? 
          <div id="custom-header1" style={{backgroundImage: 'url('+baseUrl+'/uploads/content/'+content_array[0].contact+')',}}>
                <div className="custom-header-content1">
                <div className="container">
                    <div className="inner-wrapper">
                    <div className="col-grid-6">
                        <h1 style={{display:'none'}}> Contact Us</h1>
                        <h2 className="white">Let's plan a project</h2>
                        <div className="inner-wrapper">
                        <div className="col-grid-6">
                            <input
                            ref={this.nameRef}
                            type="text"
                            name="name"
                            value={name}
                            id="name"
                            className="form-control"
                            placeholder="Name *"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="col-grid-6">
                            <input
                             ref={this.emailRef}
                            type="text"
                            name="email"
                            value={email}
                            id="email"
                            className="form-control"
                            placeholder="Email *"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="col-grid-6">
                            <input
                             ref={this.phoneRef}
                            type="text"
                            name="phone"
                            value={phone}
                            id="phone"
                            className="form-control"
                            placeholder="Phone No *"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="col-grid-6">
                            <input
                             ref={this.pinRef}
                            type="text"
                            name="pin"
                            value={pin}
                            id="pin"
                            className="form-control"
                            placeholder="PIN Code *"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="col-grid-12">
                            <input
                             ref={this.subjectRef}
                            type="text"
                            name="subject"
                            value={subject}
                            id="subject"
                            className="form-control"
                            placeholder="Subject *"
                            onChange={this.onChange}
                            />
                        </div>
                        <div className="col-grid-12">
                            <textarea
                             ref={this.messageRef}
                            className="form-control"
                            name="message"
                            value={message}
                            rows="4"
                            placeholder="Message here..."
                            onChange={this.onChange}
                            ></textarea>
                        </div>
                        <div className="col-grid-6">
                            <button
                            style={{marginTop:"0px"}}
                            type="submit"
                            value="SEND"
                            id="submit"
                            className="custom-button button-medium"
                            onClick={this.onSubmit}
                            >
                            Submit
                            </button>
                        </div>
                        </div>
                    </div>
                    <div className="col-grid-6">
                      <div className="get-in-touch">
                        <h2>GET IN TOUCH</h2>
                        <span>
                        <FontAwesomeIcon icon={faMapMarker}/> {content_array[0].web_address}
                        </span>
                        <span>
                        <FontAwesomeIcon icon={faPhone}/>{" "}
                          <a href={"tel:"+content_array[0].web_phone.replace(/ /g,'')}>{content_array[0].web_phone}</a>{" "}
                        </span>
                        <span>
                        <FontAwesomeIcon icon={faEnvelope}/>{" "}
                          <a href={"mailto:"+content_array[0].web_email}>{content_array[0].web_email}</a>{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                {/* <!-- .custom-header-content --> */}
                {spotlight.length>0 ? 
                <aside className="overlay-enabled">
                  <div className="container">
                    <div className="related-products">
                      <Carousel
                        responsive={contact_carousel}
                        arrows
                        autoPlay
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                      > 
                      {spotlight.map((proj)=>{
                        if(proj.image1 != ""){
                            return( 
                                <ProductCard application={proj.proj_type} id={proj.id} image1={proj.image1} title={proj.title} />
                            )
                        }else{ return(this.randomImage(proj, spotlight))}
                    })}
                      </Carousel>
                    </div>
                  </div>
                </aside> : ''}
            </div>
            : ''}
              
            <Footer/>
        </div>
        
        <div id="btn-whatsapp">
          <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1
">
          </a>
        </div>
      </>
    );
  }
}

export default Contact;
