const HOME = "/";
const FAQ = "/faq";
const CONTACT = "/contact";
const OURWORK = "/our-work";
const ROOF = "/roof";
const DOME = "/dome";
const ABOUT = '/about';
const PRODUCT_ROOF = "/roofs-and-enclosures/:id";
const PRODUCT_DOME = "/domes/:id";
const PROJECT_ROOF = '/roof-and-enclosures/:id';
const PROJECT_DOME = '/dome/:id';
const BLOG = '/blog';
const BLOG_CONTENT = '/blog/:title';
const THANK_YOU = '/thank-you'

export {
  HOME,FAQ,CONTACT,OURWORK,ROOF,PRODUCT_ROOF,PRODUCT_DOME,ABOUT,PROJECT_ROOF,PROJECT_DOME,DOME,BLOG, BLOG_CONTENT,THANK_YOU
}
