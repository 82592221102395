import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header'; 
import axios from 'axios';
import ImageCard from '../../components/imageCard';
import SEOHelmet from '../../components/SEOHelmet';
import PageContentBox from '../../components/pageContentBox';
import { useLocation } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;

const Roofs = ()=> {
    const [businessList,setBusinessList] = useState([]);
    const [homesList,setHomesList] = useState([]);
    const [othersList,setOthersList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [tabs, setTabs] = useState('1');
    const [content, setContent] = useState([]);
    const [seo, setSeo] = useState('');
    const [description, setDescription] = useState('');
    const [fixedHeader, setFixedHeader] = useState(false);
    const location = useLocation();

    useEffect(()=>{
        setLoading(true);
        axios.get(`${baseUrl}/roofs/`).then((res) => {
            const roofs = res.data.data;
            const business = roofs.filter(roof=>roof.application == 'Business' && roof.type == '1')
            const homes = roofs.filter(roof=>roof.application == 'Homes' && roof.type == '1')
            const others = roofs.filter(roof=>roof.application == 'Other')
            setBusinessList(business);
            setHomesList(homes);
            setOthersList(others);
            setLoading(false);
            // setTimeout(() => {
            //     setLoading(false)
            // }, 1000);
        })
        axios.get(`${baseUrl}/content/roof`).then((res) => {
            const content = res.data.content;
            const seo = res.data.seo[0];
            const description = res.data.description[0];
            setContent(content);
            setSeo(seo);
            setDescription(description);
        })
        
    }, [])

    const randomImage = (data, array)=>{
        const random = Math.floor(Math.random() * array.length);
        let randomImg = array[random].image;
        if(randomImg != ''){
            return <div className="col-grid-6 team-item">
                <ImageCard application='roofs-and-enclosures' key={data.id} link={data.link} image={randomImg} title={data.title} prod_title={data.prod_title}/>
            </div>
        }else{
            randomImage(data, array)
        }
       
    }
    
    const scrollHandler = (event) => {
        event.preventDefault();
        const scrollTop = event.currentTarget.scrollTop;
        if(scrollTop>100){
          setFixedHeader(true);
        }else{
          setFixedHeader(false);
        }
      };

    return (
        <>
          { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical={location.pathname} /> }
        <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
         <Header type={fixedHeader}/>
           {content.length>0 ? 
           <div className='website-page-header'>
                <img src={baseUrl+'/uploads/content/'+content[0].roofs} />
                <h1 className="page-title text-aligncenter">Roofs & Enclosures</h1>
            </div>
            // <div id="custom-header" style={{backgroundImage: 'url('+baseUrl+'/uploads/content/'+content[0].roofs+')',}}>
            // <div className="custom-header-content">
            //     <div className="container">
            //         <h1 className="page-title text-aligncenter">Roofs & Enclosures</h1>
            //     </div>
            // </div>
            // </div>
           : ''}
            <div className="site-content global-layout-no-sidebar roofbg">
            <div className="container">
                <div className="inner-wrapper">
                    <div id="primary" className="content-area">
                        <main id="main" className="site-main">
                            <aside className="section">
                                <div className="section-teams">
                                    <div className="container roofs-list">
                                        <div className='row tabs'>
                                            <div className={`col-6 ${tabs ==='1' ? 'active' : ''}`}>
                                                <p onClick={()=>setTabs('1')}>For Business</p>
                                            </div>
                                            <div className={`col-6 ${tabs ==='2' ? 'active' : ''}`}>
                                                <p onClick={()=>setTabs('2')}>For Homes</p>
                                            </div>
                                        </div>
                                        <div className={`col-grid-6 ${tabs ==='1' ? 'active' : 'none'}`}>
                                            <div className="teams-section roofbg-business">
                                                <h2>For Business</h2>
                                                <div className="inner-wrapper">
                                                    {businessList.map((business)=>{
                                                        if(business.image != ""){
                                                            return(<div className="col-grid-6 team-item">
                                                                <ImageCard application='roofs-and-enclosures' key={business.id} link={business.link} image={business.image} title={business.title} prod_title={business.prod_title}/>
                                                            </div>)
                                                        }else{ return(randomImage(business, businessList))}
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={`col-grid-6 ${tabs ==='2' ? 'active' : 'none'}`}>
                                            <div className="teams-section roofbg-home">
                                                <h2>For Homes</h2>
                                                <div className="inner-wrapper">
                                                    {homesList.map((homes)=>{
                                                        if(homes.image != ""){
                                                            return( 
                                                                <div className="col-grid-6 team-item">
                                                                    <ImageCard application='roofs-and-enclosures' key={homes.id} link={homes.link} image={homes.image} title={homes.title} prod_title={homes.prod_title}/>
                                                                </div>
                                                            )
                                                        }else{ return(randomImage(homes, homesList))}
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </main>
                    </div>
                </div>
            </div>
            <div className="other_roofs">
                <div className='tabs'>
                    <p >Others</p>
                </div>
                <div className="col-grid-12 ">   
                    <div className="teams-section roofbg-other roofbg-business">
                        <div className="other_cont">
                            <h2>Others</h2>
                            <div className="inner-wrapper">
                                <div className="section-teams">
                                {othersList.map((other)=>{
                                    if(other.image != ""){
                                        return( 
                                            <div className="col-grid-6 team-item">
                                                <ImageCard application='roofs-and-enclosures' key={other.id} link={other.link} image={other.image} title={other.title} prod_title={other.prod_title}/>
                                            </div>
                                        )
                                    }else{ return(randomImage(other, othersList))}
                                })}
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>                 
                </div>
            </div>
            <PageContentBox page='2' content={description.description} />
            <Footer />
        </div>
        <div id="btn-whatsapp">
            <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
            </a>
        </div>
        </>
    );
}

export default Roofs;
