import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappIcon, 
  WhatsappShareButton,
  TwitterShareButton,
  TwitterIcon,
  TelegramShareButton,
  TelegramIcon,
  LinkedinShareButton, 
  LinkedinIcon

} from "react-share";

const baseUrl = process.env.REACT_APP_SITE;

const ShareModal = (props) => {
  const shareUrl=`${baseUrl}/blog/${props.url}`;
  return (
    <>
      <Modal
      size="md"
      show={props.show}
      onHide={props.handleClose}
      backdrop="static"
      keyboard={false}
      centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body class='media-share'>
          <h3>Share this link via</h3>
          <div className='icons'>
            <FacebookShareButton url={shareUrl}> 
              <FacebookIcon size={50} round={true} />
            </FacebookShareButton>

            <WhatsappShareButton url={shareUrl}> 
              <WhatsappIcon size={50} round={true}/>
            </WhatsappShareButton>

            <TwitterShareButton url={shareUrl}> 
              <TwitterIcon size={50} round={true} />
            </TwitterShareButton>
            
            <EmailShareButton url={shareUrl}> 
              <EmailIcon size={50} round={true} />
            </EmailShareButton>

            <TelegramShareButton url={shareUrl}> 
              <TelegramIcon size={50} round={true} />
            </TelegramShareButton>

            <LinkedinShareButton url={shareUrl}> 
              <LinkedinIcon size={50} round={true} />
            </LinkedinShareButton>
          </div>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={props.handleClose}>
            Close
          </Button>
          <Button variant="primary">Understood</Button>
        </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default ShareModal;