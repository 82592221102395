import React, { Component } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import axios from "axios";
import "../pages.css";
import "react-toastify/dist/ReactToastify.css";
import SEOHelmet from "../../components/SEOHelmet";
import ProductCard from "../../components/productCard";
import Carousel from "react-multi-carousel";
import { contact_carousel } from "../../utils";
import ThankYouGif from "../../../assets/images/thank-you.gif";

const baseUrl = process.env.REACT_APP_API;

class ThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content_array: [],
      spotlight: [],
      fixedHeader: false,
      seo: "",
    };
  }

  componentDidMount() {
    axios.get(`${baseUrl}/content/thank-you`).then((res) => {
      const content_array = res.data.content;
      const seo = res.data.seo[0];
      this.setState({ content_array });
      this.setState({ seo });
    });
    axios.get(`${baseUrl}/project/`).then((res) => {
      const projects = res.data.data;
      const spotlight = projects.filter((project) => project.spotlight == 1);
      this.setState({ spotlight });
    });
  }

  scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if (scrollTop > 100) {
      this.setState({ fixedHeader: true });
    } else {
      this.setState({ fixedHeader: false });
    }
  };

  randomImage = (proj, array) => {    
    let randomImg = array[array.length - 1].image1;
    if (randomImg != "") {
      return (
        <ProductCard
          application={proj.proj_type}
          id={proj.id}
          image1={randomImg}
          title={proj.title}
        />
      );
    } else {
      this.randomImage(proj, array);
    }
  };

  render() {
    const {
      content_array,
      fixedHeader,
      seo,
      spotlight,
    } = this.state;
    return (
      <>
        {seo != "" && (
          <SEOHelmet
            title={seo.title}
            description={seo.description}
            canonical="/contact"
          />
        )}        
        <div
          className="scroll-div site"
          id="page"
          onScroll={(e) => this.scrollHandler(e)}
        >
          <Header type={fixedHeader} />
          {content_array.length > 0 ? (
            <div
              id="custom-header1"
              style={{
                backgroundImage:
                  "url(" +
                  baseUrl +
                  "/uploads/content/" +
                  content_array[0].thankyou +
                  ")",
              }}
            >
              <div className="custom-header-content1">
                <div className="container">
                  <div className="d-flex justify-content-center">
                    <div style={{ background: "white", borderRadius: "5px" }} className="col-grid-8 text-center pt-4 pb-4">
                      <img className="mb-4" width={300} height={300} src={ThankYouGif} alt="Thank you"></img>
                      <h4 className="mb-4">Thank you for contacting us!</h4>
                      <p className="mb-2">We have received your message</p>
                      <p className="mb-2">We'll reach you out shortly!</p>
                    </div>
                  </div>
                </div>
              </div>              
              {spotlight.length > 0 ? (
                <aside className="overlay-enabled">
                  <div className="container">
                    <div className="related-products">
                      <Carousel
                        responsive={contact_carousel}
                        arrows
                        autoPlay
                        autoPlaySpeed={3000}
                        centerMode={false}
                        className=""
                        containerClass="container-with-dots"
                        dotListClass=""
                        draggable
                        focusOnSelect={false}
                        infinite
                        itemClass=""
                        keyBoardControl
                        minimumTouchDrag={80}
                        renderButtonGroupOutside={false}
                        renderDotsOutside={false}
                      >
                        {spotlight.map((proj) => {
                          if (proj.image1 != "") {
                            return (
                              <ProductCard
                                application={proj.proj_type}
                                id={proj.id}
                                image1={proj.image1}
                                title={proj.title}
                              />
                            );
                          } else {
                            return this.randomImage(proj, spotlight);
                          }
                        })}
                      </Carousel>
                    </div>
                  </div>
                </aside>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          <Footer />
        </div>

        <div id="btn-whatsapp">
          <a
            title="Go Top"
            className="scrollup"
            target="_blank"
            href="https://wa.me/message/OE4RSQO3BOWQI1
"
          ></a>
        </div>
      </>
    );
  }
}

export default ThankYou;
