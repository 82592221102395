import React, { Component } from "react";

import Header from "../../components/header/";
import Footer from "../../components/footer";
import axios from "axios";
import Carousel from "react-multi-carousel";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import SEOHelmet from '../../components/SEOHelmet';
import AboutCard from "../../components/aboutCard";
import Spotlight from "../../components/spotlight";

const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projects : [],
      totprojects:[],
      fixedHeader:false,
      seo:'',
      content:'',
    };
  }

  componentDidMount() {

    axios.get(`${baseUrl}/content/about`).then((res) => {
      const content = res.data.content;
      const seo = res.data.seo[0];
      this.setState({ content });
      this.setState({ seo });
    })

    axios.get(`${baseUrl}/about/`, { params: { id: 1 } }).then((res) => {
      const about = res.data.data;
      this.setState({ about });
    });

    axios.get(`${baseUrl}/project/`).then((res) => {
      const totprojects = res.data.data;
      const projects = res.data.data.filter(project=>project.spotlight == 1).slice(0,3);
      this.setState({totprojects});
      this.setState({ projects });
    });

    axios.get(`${baseUrl}/product/`).then((res) => {
      const products = res.data.data;
      this.setState({ products });
    });

    this.setState({ showResults: false });
    this.setState({ showButton: false });

  }
  scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      this.setState({fixedHeader:true});
    }else{
      this.setState({fixedHeader:false});
    }
  };

  randomImage = (prod, array)=>{
    const random = Math.floor(Math.random() * array.length);
    let randomImg = array[random].image2;
    if(randomImg != ''){
        return <AboutCard id={prod.id} image={randomImg} title={prod.title}/>
    }else{
        this.randomImage(prod, array)
    }
  }

  render() {
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 2,
      },
    };
    const handleshowmore = (event) => {
      var filter = event.target.value;
       axios.get(`${baseUrl}/project/`).then((res) => {
           const projects = res.data.data.filter(project=>project.spotlight == 1);;
           this.setState({ projects });
           this.setState({ showResults: true });
       })
     };
     const handleshowless = (event) => {
      var filter = event.target.value;
       axios.get(`${baseUrl}/project/`).then((res) => {
           const projects = res.data.data.filter(project=>project.spotlight == 1).slice(0,3);
           this.setState({ projects });
           this.setState({ showResults: false });
       })
     };
     if(this.state.totprojects.length > 3){
       this.setState({showButton:true });
     }
    const { about, fixedHeader, products, projects, seo, content } = this.state;
    const imgUrl = `${baseUrl}/uploads/about/`;
    const prodimg = `${baseUrl}/uploads/product/images/`;
    const projimg = `${baseUrl}/uploads/project/images/`;
    return (
      <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical='/about' /> }
        <div className='scroll-div' onScroll={(e)=>this.scrollHandler(e)}>
        <Header type={fixedHeader}/>
        <style>
          {`#about-header{
            padding:0px;
          }
          #masthead.overlap-header{
            background: #000;
            opacity:0.5;
          }
          #masthead.overlap-header.sticky-enabled.sticky-header{
            opacity:1;
          }
          `}
        </style>
        <div id="about-header">
          <div className="custom-header-content"></div>
        </div>
        <div
          id="content"
          className="site-content global-layout-no-sidebar about-us"
        >
          <div className="container layout about">
            <div className="inner-wrapper">
              <div id="primary" className="content-area">
                {(about || []).map((val, i) => (
                  <main id="main" className="site-main">
                    <div className="section-featured-page">
                      <div className="container about-us" style={{marginBottom:'25px'}}>
                        <div className="inner-wrapper banner">
                          <div className="col-grid-4">
                            <div
                              className="featured-page-section  text-alignjustify"
                              dangerouslySetInnerHTML={{
                                __html: val.description,
                              }} //style="padding:50px 0px;"
                            >
                              {/* <p>Megavent Technologies Pvt Ltd, established in 1995 is a trusted name in the field of retractable roof and enclosures solutions.</p>
                                                <p>Our team of experts provide highly creative and customized solutions that suit commercial, industrial and domestic sectors. A well equipped manufacturing unit, competent design team, well trained installation team, an onsite support team and our focus on high calibre workmanship is what sets us apart in the industry. We have successfully manufactured and installed 600+ roofs and enclosures across India. We export our products overseas to UK,UAE, USA and Canada. Megavent provides 100% protection against water leakages, 15 years warranty on the powder coating, 10 years warranty on the polycarbonate sheets and 3 years warranty on the EPDM gaskets. </p>
                                                <p>We aspire to be a global leader in retractable roof and enclosure solutions with high quality services and to constantly enhance quality through design, innovation and customer centric creative engineering. </p> */}
                            </div>
                          </div>
                          <div className="col-grid-8 about-banner" //style="margin-top:0px;"
                          >
                            <img
                              className="alignnone"
                              alt="About Us"
                              src={imgUrl + val.image}
                            />
                             <h1 style={{display:'none'}}> About Us</h1>
                          </div>
                        </div>
                      </div>
                    </div>
                    <aside className="section achievementsbg">
                      <div className="section-featured-page-grid">
                        <div className="container">
                          <div className="section-title-wrap text-alignleft text-alignleft">
                            <h2 className="section-title white">
                              Projects under spotlight
                            </h2>
                          </div>
                          <div className="featured-page-grid-section">
                            <div className="inner-wrapper">
                            {projects.map((proj) => (
                              <Spotlight application={proj.proj_type} id={proj.id} image={proj.image1} title={proj.title} location={proj.location}/>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="loadmore_btn">
                      <button className={this.state.showResults || !this.state.showButton ? "hidden" : ""} onClick={handleshowmore}>Show more</button>
                      <button className={this.state.showResults || !this.state.showButton  ? '': 'hidden'} onClick={handleshowless}>Show less</button>
                      
                      </div>
                    </aside>
                    <aside className="section products">
                      <div className="section-featured-page-grid featured-page-grid-2">
                        <div className="container">
                          <div className="inner-wrapper">
                            <div className="col-grid-3">
                              <div className="section-title-wrap text-alignleft">
                                <h3
                                  className="section-title text-alignleft" //style="font-size:24px; margin-top:30px;"
                                >
                                  Megavent Products{" "}
                                </h3>
                                <hr />
                                <p className=" text-alignjustify">
                                  Take a look at the wide variety of products
                                  megavent has to offer for commercial, domestic
                                  and Industrial sectors.{" "}
                                </p>
                              </div>
                            </div>
                            <div className="col-grid-9">
                              <div className="featured-page-grid-section">
                                <div className="inner-wrapper">
                                  <div
                                    className="section-carousel-enabled normal-carousel"
                                    data-slick='{"slidesToShow":3,"dots":true,"arrows":false,"responsive":[{"breakpoint":1024,"settings":{"slidesToShow":3}},{"breakpoint":800,"settings":{"slidesToShow":2}},{"breakpoint":659,"settings":{"slidesToShow":1}}]}'
                                  >
                                    <Carousel
                                      responsive={responsive}
                                      infinite={true}
                                      swipeable={false}
                                      draggable={false}
                                      autoPlay={true}
                                      autoPlaySpeed={3000}
                                    >
                                      {/* {(products || []).map((prod)=>{
                                        if(prod.image2 != ""){
                                            return( 
                                                <RelatedItem id={prod.id} image2={prod.image2} title={prod.title} />
                                            )
                                        }else{ return(this.randomImage(prod, products))}
                                      })} */}
                                      {(products || []).map((prod, i) => {
                                        if(prod.image2 != ""){
                                          return  <AboutCard application={prod.type} id={prod.id} image={prod.image2} title={prod.title}/>
                                        }
                                        else{
                                          let random = products.length-1;
                                          return <AboutCard application={prod.type} id={prod.id} image={products[random].image2} title={prod.title}/>
                                        }
                                      })}
                                    </Carousel>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </aside>
                    <aside
                      className="section quote" style={{ background: "#E4E4E4", padding:"0px"}}
                    >
                      <div className="container text-aligncenter">
                        <div className="col-grid-12">
                          <Link
                            className="custom-button button-medium"
                            to="/contact" //style="margin-top:0px;"
                          >
                            Get A Quote
                          </Link>
                        </div>
                      </div>
                    </aside>
                  </main>
                ))}
              </div>
            </div>
          </div>
        </div>
        <Footer />
        </div>
        <div id="btn-whatsapp">
          <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
          </a>
        </div>
      </>
    );
  }
}
export default About;
