import React from 'react';
import { Link } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API;

function AboutCard(props) {
  const application =  props.application == 1 ? 'roofs-and-enclosures' : 'domes';
  let title = props.title.split('-').join(':');
  title = title.split(' ').join('-');
  title = title.split('/').join('+');
  const prodimg = `${baseUrl}/uploads/product/images/`;
  return (
    <div className="featured-page-grid-item ">
      <Link to={{ pathname: `/${application}/${title}`}} state={{ id: props.id }} className="featured-page-grid-wrapper">
        <div className="featured-page-grid-thumb thumb-overlay box-border-shadow">
            <img alt="img1" src={prodimg + props.image} height='140px' width='100%'/>
            <div className="title-box">
              <h3>{props.title}</h3>
            </div>
        </div>
      </Link>
    </div>
  );
}

export default AboutCard;
