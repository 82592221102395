import React, { Component, useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Spinner } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import { render } from "@testing-library/react";
import { Link } from 'react-router-dom';
import SEOHelmet from '../../components/SEOHelmet';
import { useLocation } from "react-router-dom";
import Oops404 from "../../components/Oops404";

const baseUrl = process.env.REACT_APP_API;

function Product(props) {
  const [product,setProduct] = useState([]);
  const [banner,setBanner] = useState([]);
  const [fixedHeader,setFixedHeader] = useState(false);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [page404, sePage404] = useState(false);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const bannerresp = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(()=>{
    setLoading(true);
    // let prod_id = location.state.id;
    const url = window.location.href.split("/");
    var proj_name = url[4];
    proj_name = proj_name.split('-').join(' ');
    proj_name = proj_name.split(':').join('-');
    proj_name = proj_name.split('+').join('/');

    axios.get(`${baseUrl}/project/id`,{ params: { id: proj_name } }).then((res) => {
      const product = res.data.data;
      if(product.length == 0){
        sePage404(true);
      }else{
        setProduct(product);
        setLoading(false);
        var value = product[0].image;
        const banner = value.toString().split(',');
        setBanner(banner);
      }
    });
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      setFixedHeader(true);
    }else{
      setFixedHeader(false);
    }
  };

  return (
    <>
        { product.length >0 && <SEOHelmet title={product[0].seo_title} description={product[0].seo_description} canonical={location.pathname} /> }
        { page404  ? <Oops404 /> : 
        <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
        <Header type={fixedHeader}/>
        <style>
          {`#masthead.overlap-header{
      position:relative!important;
      background: #000;
    opacity:0.5;
    color:#fff;
  }
#about-header{
    padding:0px;
  }
  #masthead.overlap-header.sticky-enabled.sticky-header{
    opacity:1;
  }
  `}
        </style>
        <style></style>          
          <div className="site-content global-layout-no-sidebar">
            <div id="primary" className="content-area">
              <main id="main" className="site-main">
                <div className="project_section">
              <div className="project-cont left">
              <Carousel responsive={bannerresp}
                arrows
                autoPlay
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                >
          
          {banner.map((prod,i) => (
            <div className="carousel-inner banner">
            <div className="section no-padding carousel-item project">
              <img
                className="d-block w-100"
                src={baseUrl+ '/uploads/project/images/' + prod}
                alt="First slide"
              />
              <div className="carousel-caption">
                <h3 className="section-title product">{prod.title}</h3>
              </div>
              </div>
          </div>
             ))}
        </Carousel>
        </div>
        <div className="project-cont right">
            <div className="section-project">
                <div className="col-grid-12">
                {product.map((prod,i) => (
                  <div className="teams-section">
                    <div className="col-grid-12 service-block-item text-aligncenter">
                        <h1 className="section-title">{prod.title}</h1>
                    </div>
                    <div className="col-grid-12 service-block-item text-aligncenter">
                        <h4 className="section-title">{prod.location}</h4>
                    </div>
                    <div className="col-grid-12 service-block-item text-alignjustify">
                      <p
                        className="section-description text-alignjustify"
                          dangerouslySetInnerHTML={{
                            __html: prod.description,
                          }}
                      ></p>
                    </div>
                  </div>
                ))}
                </div>
                  </div>
                  </div>
                  <div className="clear"></div>
                  </div>
              </main>
            </div>
          </div>
        {/* // ))} */}
        <Footer  />
        </div>}
        <div id="btn-whatsapp">
            <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
            </a>
        </div>
      </>
  );
}

export default Product;