import React, {useState, useEffect} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import {Image, Row, Col,} from 'react-bootstrap';
import Carousel from "react-multi-carousel";
import ShareModal from '../../components/blog/shareModal';
import { res_carousel, bannerresp } from "../../utils";
import Oops404 from "../../components/Oops404";
import axios from 'axios';
import * as moment from 'moment';
import SEOHelmet from '../../components/SEOHelmet';
import { useLocation } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt} from '@fortawesome/free-solid-svg-icons'

const baseUrl = process.env.REACT_APP_API;

function Content(props) {
  const [loading, setLoading] = useState(true);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [show, setShow] = useState(false);
  const [page404, setPage404] = useState(false);
  const [blog, setBlog] = useState({});
  const [banner, setBanner] = useState([]);
  const [url, setUrl] = useState('');
  let location = useLocation();


  useEffect(()=>{

    setLoading(true);
    const url = window.location.href.split("/");
    setUrl(url[4]);
    var blog_title = url[4];
    blog_title = blog_title.split('-').join(' ');
    blog_title = blog_title.split(':').join('-');
    blog_title = blog_title.split('+').join('/');
    
    axios.get(`${baseUrl}/blog/${blog_title}`).then((res) => {
      if(res.data.success){
        let blog = res.data.data;
        blog['formatted_date'] = moment(parseInt(blog.date)).format("MMM D");
        setBlog(blog);
        let banner = blog.images.split(',');
        setBanner(banner);
        console.log(blog, "blog-detail");
      }else{
        setPage404(true);
      }
    });

  }, [])
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createMarkup = (content)=>{
    return {__html: content};
  }

  const scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      setFixedHeader(true);
    }else{
      setFixedHeader(false);
    }
  };

  return (
    <div>
    { <SEOHelmet title={blog.seo_title} description={blog.seo_desc} canonical={location.pathname} /> }
    { page404  ? <Oops404 /> : 
      <>
      <ShareModal show={show} handleClose={handleClose} url={url}/>
      <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
        <Header type={fixedHeader} />
        <style>{`#masthead.overlap-header{background: #000;}`}</style>
      
        <div className="site-content blog-detail">
          <aside className="container">
            <Image src={baseUrl+'/uploads/blog/'+blog.head_image} alt='blog'/>
            <div className='about-blog'>
              <h1 className='mb-3' dangerouslySetInnerHTML={createMarkup(blog.title)}></h1>
              <p className='time mb-3'>{blog.formatted_date} . {blog.read_time}  <FontAwesomeIcon icon={faShareAlt} className="ml-2" onClick={handleShow}/> </p>
              <p className='mb-0' dangerouslySetInnerHTML={createMarkup(blog.desc_1)}></p>
            </div>
          </aside>
          {banner.length > 0 && 
          <aside className='blog-carousel'>
            <div className='container'>
            <Carousel
              responsive={bannerresp}
              arrows
              autoPlay
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
            >
              {banner.map((img, i) => (
                <div className="carousel-inner banner">
                  <div className="no-padding carousel-item">
                    <Image
                      className="d-block w-100 blog-img"
                      src={baseUrl+'/uploads/blog/' + img}
                      alt="First slide"
                    />
                  </div>
                </div>
              ))}
            </Carousel>
         
            </div>
          </aside> }
          <aside className="blog-body">
            <div className="container">
              <p className='mb-0 blog-desc' dangerouslySetInnerHTML={createMarkup(blog.desc_2)}></p>
              <Row className='mt-5'>
                <Col xl={8} lg={7} >
                  <Image src={baseUrl+'/uploads/blog/'+blog.footer_image} alt='blog' />
                </Col>
                <Col xl={4} lg={5}>  <p className='mb-0 blog-desc' dangerouslySetInnerHTML={createMarkup(blog.desc_3)}></p> </Col>
              </Row>
            </div>
          </aside>
       

        </div>
        <Footer /> 
      </div>
      <div id="btn-whatsapp">
        <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
        </a>
      </div>
      </>}
    </div>
  );
}

export default Content;