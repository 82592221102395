import React,{ useState, useEffect}  from 'react';
import {Routes, Route, Navigate } from 'react-router-dom';
import * as path from './routes';
import ContactWithNavigation from '../pages/contact/contact-navigation';
import Faq from '../pages/faq';
import Home from '../pages/home';
import OurWork from '../pages/our-work';
import Roofs from '../pages/roofs';
import Product from '../pages/product';
import About from '../pages/about-us';
import Project from '../pages/project';
import Domes from '../pages/domes';
import Launch from '../pages/launch';
import Oops404 from '../components/Oops404';
import Blog from '../pages/blog';
import Content from '../pages/blog/content';
import ThankYou from '../pages/thank-you';

export default function BaseRouter() {

  return(
    <>
        <Routes>  
            <Route path="*" element={<Oops404/>} />
            <Route path="/home" element={ <Navigate to={path.HOME} /> }/>
            {/* <Route exact path="/" element={<> < Launch/></>} /> */}
            <Route exact path={path.HOME} element={<> < Home/></>} />
            <Route exact path={path.FAQ} element={<> < Faq/></>} />
            <Route exact path={path.CONTACT} element={<> <ContactWithNavigation /></>} />
            <Route exact path={path.OURWORK} element={<> <OurWork /></>} />
            <Route exact path={path.ROOF} element={<> <Roofs /></>} />
            <Route exact path={path.DOME} element={<> <Domes /></>} />
            <Route exact path={path.ABOUT} element={<> <About /></>} />
            <Route exact path={path.PRODUCT_ROOF} element={<> <Product /></>} />
            <Route exact path={path.PRODUCT_DOME} element={<> <Product /></>} />
            <Route exact path={path.PROJECT_ROOF} element={<> <Project /></>} />
            <Route exact path={path.PROJECT_DOME} element={<> <Project /></>} />
            <Route exact path={path.BLOG} element={<> <Blog /></>} />
            <Route exact path={path.BLOG_CONTENT} element={<> <Content /></>} />
            <Route exact path={path.THANK_YOU} element={<> <ThankYou /></>} />
        </Routes>
    </>
  )
}
  