import React, {useState, useEffect} from 'react';
import BlogCard from '../../components/blog/blogCard';
import Footer from '../../components/footer';
import Header from '../../components/header';
import { Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import * as moment from 'moment';
import SEOHelmet from '../../components/SEOHelmet';
import { useLocation } from "react-router-dom";

const baseUrl = process.env.REACT_APP_API;

function Blog(props) {
  const [loading, setLoading] = useState(true);
  const [fixedHeader, setFixedHeader] = useState(false);
  const [show, setShow] = useState(false);

  const [seo, setSeo] = useState('');
  const [blog, setBlog] = useState([]);
  let location = useLocation();

  
  useEffect(()=>{
    axios.get(`${baseUrl}/content/blog`).then((res) => {
      const seo = res.data.seo[0];
      this.setState({ seo });
    });

    axios.get(`${baseUrl}/blog/`).then((res) => {
      const blog = res.data.data.sort(function(a, b){return parseInt(b.date) - parseInt(a.date)});
      blog.forEach((item, index) => {
        item.images = item.images.split(',');
        item['formatted_date'] = moment(parseInt(item.date)).format("MMM D");
        blog[index] = item;
      });
      setBlog(blog);
      console.log(blog, "blog")
    });
    
  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>50){
      setFixedHeader(true);
    }else{
      setFixedHeader(false);
    }
  };

  return (
    <div>
      { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical={location.pathname} /> }
      <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
         <Header type={fixedHeader} />
         <style>{`#masthead.overlap-header{background: #000;}`}</style>
       
         <div className="site-content blog-page">
            <div className='container'>
              <h1>Megavent Blogspot</h1>
            </div>
            {blog.map((blog, i) => (i==0) ? 
              <BlogCard type='main' active={false} blog={blog}/> : 
              <BlogCard active={ i%2==0 ? true : false} blog={blog}/> 
            )}
         </div>
         <Footer /> 
        </div>
        <div id="btn-whatsapp">
          <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
          </a>
        </div>
    </div>
  );
}

export default Blog;