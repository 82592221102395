import React,{ useState,useEffect } from 'react';
import Logo from "../../../assets/images/logo.webp";
import { Link } from 'react-router-dom';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarker, faPhone, faEnvelope} from '@fortawesome/free-solid-svg-icons'
import {faYoutube, faFacebook, faTwitter, faInstagram} from '@fortawesome/free-brands-svg-icons'
const baseUrl = process.env.REACT_APP_API;

function Footer(props) {
  const [content, setContent] = useState([]);

  useEffect(()=>{
    axios.get(`${baseUrl}/content/`).then((res) => {
      const content = res.data.data;
      setContent(content);
    });
  }, []);

  return (
    <>
        <div className="footer-container medium ">
        {content.map((cont)=> (
        <div id="footer-widgets">
          <div className="container">
            <div className="inner-wrapper">
              <aside className="col-grid-3 footer-widget-area social-links top">
                <p className="footer-logo">
                  <Link to={'/'} rel="home">
                    <img alt="logo" src={Logo} className='footer-img' />
                  </Link>
                </p>
                <div className="social-links circle">
                  <ul className="text-aligncenter row">
                  <li className="col">
                    <a href={cont.youtube_link} target="_blank"><FontAwesomeIcon icon={faYoutube} />      </a>
                  </li>
                  <li className="col">
                    <a href={cont.instagram_link} target="_blank"> <FontAwesomeIcon icon={faInstagram}/></a>
                  </li>
                  <li className="col">
                    <a href={cont.facebook_link} target="_blank"> <FontAwesomeIcon icon={faFacebook} /></a>
                  </li>
                  <li className="col">
                    <a href={cont.twitter_link} target="_blank"> <FontAwesomeIcon icon={faTwitter}/></a>
                  </li>
                    {/* <li>
                      <a href={cont.youtube_link} target="_blank"></a>
                    </li>
                    <li>
                      <a href={cont.instagram_link} target="_blank"></a>
                    </li>
                    <li>
                      <a href={cont.facebook_link} target="_blank"></a>
                    </li>
                    <li>
                      <a href={cont.twitter_link} target="_blank"></a>
                    </li> */}
                  </ul>
                </div>
              </aside>
              <aside className="col-lg-2 col-sm-2 footer-widget-area first">
                <ul>
                  <li>
                    <Link to={'/'}>Home</Link>
                  </li>
                  <li>
                    <Link to={'/about'}>About Us</Link>
                  </li>
                  <li>
                    <Link to={'/our-work'}>Our Work</Link>
                  </li>
                  <li>
                    <Link to={'/contact'}>Contact Us</Link>
                  </li>
                  <li>
                    <Link to={'/blog'}>Blog</Link>
                  </li>
                </ul>
              </aside>
              <aside className="col-lg-3 col-sm-2 footer-widget-area second">
                <ul>
                  <li>
                    <Link to={'/roof'}>Retractable roofs & Enclosures</Link>
                  </li>
                  <li>
                    <Link to={'/dome'}>Dome</Link>
                  </li>
                  <li>
                    {/* <Link to={cont.brouchure_link}>Download Brochure</Link> */}
                    <a href={cont.brouchure_link} target="_blank">Download Brochure</a>
                  </li>
                  <li>
                    <Link to={'/faq'}>FAQs</Link>
                  </li>
                </ul>
              </aside>
              <aside className="col-lg-4 col-sm-5 footer-widget-area third">
                <div className="widget-quick-contact">
                  <span>
                    <FontAwesomeIcon icon={faMapMarker}/>{cont.web_address}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faPhone}/>{" "}
                    <a href={"tel:"+cont.web_phone.replace(/ /g,'')}>{cont.web_phone}</a>{" "}
                  </span>
                  <span>
                    <FontAwesomeIcon icon={faEnvelope}/>{" "}
                    <a href={"mailto:"+cont.web_email}>{cont.web_email}</a>{" "}
                  </span>
                  {/* <span>
                    <i className="fa fa-map-marker"></i> {cont.web_address}
                  </span>
                  <span>
                    <i className="fa fa-phone" aria-hidden="true"></i>{" "}
                    <a href={"tel:"+cont.web_phone.replace(/ /g,'')}>{cont.web_phone}</a>{" "}
                  </span>
                  <span>
                    <i className="fa fa-envelope" aria-hidden="true"></i>{" "}
                    <a href={"mailto:"+cont.web_email}>{cont.web_email}</a>{" "}
                  </span> */}
                </div>
              </aside>
            </div>
          </div>
        </div>
        ))}
        <footer id="colophon" className="site-footer">                 
          <div className="colophon-bottom">
          <div className="social-links bottom circle">
          {content.map((cont)=> (
                <ul className="text-aligncenter row">
                  <li className="col">
                    <a href={cont.youtube_link} target="_blank"><FontAwesomeIcon icon={faYoutube} />      </a>
                  </li>
                  <li className="col">
                    <a href={cont.instagram_link} target="_blank"> <FontAwesomeIcon icon={faInstagram}/></a>
                  </li>
                  <li className="col">
                    <a href={cont.facebook_link} target="_blank"> <FontAwesomeIcon icon={faFacebook} /></a>
                  </li>
                  <li className="col">
                    <a href={cont.twitter_link} target="_blank"> <FontAwesomeIcon icon={faTwitter}/></a>
                  </li>
                 
                  </ul>
          ))}
                </div>
            <div className="container">
              <p>© Copyright Megavent 2022. All Rights Reserved</p>
            </div>
          </div>
        </footer>
        
      </div>
      <div className="small footer-container">
      {content.map((cont)=> (
        <div id="footer-widgets">
          <div className="container">
            <div className="">
              <div className="row">
                <div className="col-5">
                  <ul>
                    <li>
                      <Link to={'/'}>Home</Link>
                    </li>
                    <li>
                      <Link to={'/about'}>About Us</Link>
                    </li>
                    <li>
                      <Link to={'/our-work'}>Our Work</Link>
                    </li>
                    <li>
                      <Link to={'/contact'}>Contact Us</Link>
                    </li>
                    <li>
                      <Link to={'/blog'}>Blog</Link>
                    </li>
                  </ul>
                </div>
                <div className="col-7">
                  <ul>
                    <li>
                      <Link to={'/roof'}>Roofs & Enclosures</Link>
                    </li>
                    <li>
                      <Link to={'/dome'}>Dome</Link>
                    </li>
                    <li>
                      <a href={cont.brouchure_link} target="_blank">Download Brochure</a>
                    </li>
                    <li>
                      <Link to={'/faq'}>FAQs</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="row">
                <div className="widget-quick-contact">
                    <span>
                      <FontAwesomeIcon icon={faMapMarker}/>{cont.web_address}
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faPhone}/>{" "}
                      <a href={"tel:"+cont.web_phone.replace(/ /g,'')}>{cont.web_phone}</a>{" "}
                    </span>
                    <span>
                      <FontAwesomeIcon icon={faEnvelope}/>{" "}
                      <a href={"mailto:"+cont.web_email}>{cont.web_email}</a>{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div className="social-links">
                <ul className="row">
                <li className="col">
                    <a href={cont.youtube_link} target="_blank"><FontAwesomeIcon icon={faYoutube} />      </a>
                  </li>
                  <li className="col">
                    <a href={cont.instagram_link} target="_blank"> <FontAwesomeIcon icon={faInstagram}/></a>
                  </li>
                  <li className="col">
                    <a href={cont.facebook_link} target="_blank"> <FontAwesomeIcon icon={faFacebook} /></a>
                  </li>
                  <li className="col">
                    <a href={cont.twitter_link} target="_blank"> <FontAwesomeIcon icon={faTwitter}/></a>
                  </li>
                  </ul>
              </div>
          </div>
        </div>
      ))}
         <div id='colophon' className="container">
              <p>© Copyright Megavent 2022. All Rights Reserved</p>
            </div>
      </div>
      </>
    
  );
}

export default Footer;