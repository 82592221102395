import { BrowserRouter as Router, HashRouter } from "react-router-dom";
import BaseRouter from "./router";
import $ from 'jquery'; 
import jQuery from 'jquery';
import "./style.css";

function App() {
  return (
   <Router basename='/'>
    <BaseRouter />
   </Router >
  //  <Router>
  //  <BaseRouter />
  // </Router >
  );
}

export default App;
