import React from 'react';
import { Link } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API;

function ProductCard(props) {
  const application =  props.application == 1 ? 'roof-and-enclosures' : 'dome';
  let title = props.title.split('-').join(':');
  title = title.split(' ').join('-');
  title = title.split('/').join('+');
  return (
    <div className="featured-page-grid-item related-items">
      <Link to={{ pathname: `/${application}/${title}`}} state={{ id: props.id }} className="image-hover-zoom box-border-shadow">
        <div className="featured-page-grid-thumb thumb-overlay">
          <img  alt="img"  src={baseUrl + '/uploads/project/images/' +props.image1}  />
          <div className="title-box">
            <h3>{props.title}</h3>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ProductCard;
