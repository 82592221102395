import React from 'react';
import { useState } from 'react';
import  { Navigate  } from 'react-router-dom'

function Oops404(props) {
  const [redirect, setRedirect] = useState(false);

  const redirectHome=()=>{
    setRedirect(true);
  }

  return (
    <>
    {redirect ? <Navigate to="/" /> :
      <div className='page-404'>
        <div className='container-404'>
          <h1>404</h1>
          <h2>OOPS!!</h2>
          <p>That page donesn't exist or is unavailable</p>
          <button
            type="submit"
            value="SEND"
            id="submit"
            className="custom-button button-medium"
            onClick={redirectHome}
          >
          Go Back to Home
          </button>
        </div>
      </div>}
    </>
  );
}

export default Oops404;