import React, { Component } from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import '../pages.css';
import $ from 'jquery';
import { Accordion } from 'react-bootstrap';
import axios from 'axios'
import SEOHelmet from '../../components/SEOHelmet';

const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;

class Faq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            faq_list : [],
            content: [],
            fixedHeader:false,
            seo:'',
        };
    }

    componentDidMount(){
        axios.get(`${baseUrl}/faq/`).then((res) => {
            const faq_list = res.data.data;
            this.setState({ faq_list })
        })

        axios.get(`${baseUrl}/content/faq`).then((res) => {
            const content = res.data.content;
            const seo = res.data.seo[0];
            this.setState({ content });
            this.setState({ seo });
        })

        // scroll
        // var $scroll_obj = $('#btn-scrollup');
        // $(window).on('scroll', function() {
        //     if ($(this).scrollTop() > 100) {
        //         $scroll_obj.fadeIn();
        //     } else {
        //         $scroll_obj.fadeOut();
        //     }
        // });

        // $scroll_obj.on('click', function() {
        //     $('html, body').animate({
        //         scrollTop: 0
        //     }, 600);
        //     return false;
        // });

    }

    scrollHandler = (event) => {
        event.preventDefault();
        const scrollTop = event.currentTarget.scrollTop;
        if(scrollTop>100){
          this.setState({fixedHeader:true});
        }else{
          this.setState({fixedHeader:false});
        }
      };

    render() {
        const { faq_list,content,fixedHeader, seo } = this.state;
        return (
          <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical='faq'/> }
          <div>
          <div id='page' className='scroll-div site' onScroll={(e)=>this.scrollHandler(e)}>
            <Header type={fixedHeader}/>
                {/* <div id="custom-header" className='faq-header' > */}
                {content.length>0 ? 
                // <div id="custom-header" style={{backgroundImage: 'url('+baseUrl+'/uploads/content/'+content[0].faq+')',}}>
                //     <div className="custom-header-content">
                //         <div className="container">
                //             <h1 className="page-title text-aligncenter">Frequently Asked Questions</h1>
                //         </div>
                //     </div>
                // </div>
                <div className='website-page-header'>
                    <img src={baseUrl+'/uploads/content/'+content[0].faq} />
                    <h1 className="page-title text-aligncenter d-none d-sm-block">Frequently Asked Questions</h1>
                    <h1 className="page-title text-aligncenter d-block d-sm-none">FAQ</h1>
                </div> : '' }
                <div className='faq-content'>
                     <div className="container">
                        <div className="inner-wrapper">
                            <div className="col-grid-12">
                                <p className="mb-30">Megavent products are custom made to order. Browse through our most frequently asked questions below. If you have additional questions, we'd love to chat.</p>
                                <Accordion >
                                    {faq_list.map((faq, i)=>
                                        <Accordion.Item eventKey={i}>
                                        <Accordion.Header> <span></span> {faq.question} </Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{
                                 __html: faq.answer,
                               }}></Accordion.Body>
                                        </Accordion.Item>
                                    )}
                                   
                                </Accordion>
                            </div>
                        </div>
                     </div>
                </div>
                <Footer/>

            </div>
            {/* <div id="btn-scrollup">
                <a  title="Go Top"  className="scrollup" href="#"><i className="fas fa-angle-up"></i></a>
            </div> */}
        </div>
        <div id="btn-whatsapp">
          <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
          </a>
        </div>
            </>
        );
    }
}

export default Faq;