import React from 'react';

function PageContentBox(props) {
  const createMarkup = ()=>{
    return {__html: props.content};
  }
  const homeStyle = { margin : '40px 0px'}
  const divStyle = { margin : '20px 0px 40px 0px'}
  return (
    <>
    {props.content != '' >0 ? 
    <aside className="page-content-box" style={props.page=='1' ? homeStyle : divStyle}>
        <div className="container">
          <div className="section-title-wrap text-alignleft">
              {/* <h2 className="section-title text-aligncenter">Our Products</h2> */}
              <p className="section-description text-alignleft" dangerouslySetInnerHTML={createMarkup()} ></p>
          </div>
        </div>
    </aside>
    : ''}
    </>
  );
}

export default PageContentBox;