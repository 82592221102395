import React from 'react';
import { useNavigate } from 'react-router-dom';
import Contact from '.';

const ContactWithNavigation = (props) => {
  const navigate = useNavigate();
  return <Contact {...props} navigate={navigate} />;
};

export default ContactWithNavigation;
