import React, {useEffect, useState, useRef} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header'; 
import axios from 'axios';
import ImageCard from '../../components/imageCard';
import SEOHelmet from '../../components/SEOHelmet';
import { useLocation } from "react-router-dom";
import PageContentBox from '../../components/pageContentBox';

const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;


const Domes = (props) => {

    const [businessList,setBusinessList] = useState([]);
    // const [homesList,setHomesList] = useState([]);
    //  const [othersList,setOthersList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [content, setContent] = useState([]);
    const [seo, setSeo] = useState('');
    const [description, setDescription] = useState('');
    const [fixedHeader, setFixedHeader] = useState(false);
    let location = useLocation();

    useEffect(()=>{
        axios.get(`${baseUrl}/roofs/`).then((res) => {
            const roofs = res.data.data;
            const business = roofs.filter(roof=>roof.type == '2')
            // const others = roofs.filter(roof=>roof.type == '2' && roof.application == "Other")
            //  setOthersList(others);
            setBusinessList(business);
            // setHomesList(homes);
            setLoading(false);
        })
        axios.get(`${baseUrl}/content/dome`).then((res) => {
            const content = res.data.content;
            const seo = res.data.seo[0];
            const description = res.data.description[0];
            setContent(content);
            setSeo(seo);
            setDescription(description);
        })
    }, [])

    const randomImage = (data, array)=>{
        const random = Math.floor(Math.random() * array.length);
        let randomImg = array[random].image;
        if(randomImg != ''){
            return  <div className="col-grid-3 team-item">
                <ImageCard application='domes' key={data.id} link={data.link} image={randomImg} title={data.title} prod_title={data.prod_title}/>
            </div>
        }else{
            randomImage(data, array)
        }
    }

    const scrollHandler = (event) => {
        event.preventDefault();
        const scrollTop = event.currentTarget.scrollTop;
        if(scrollTop>100){
          setFixedHeader(true);
        }else{
          setFixedHeader(false);
        }
      };

    return (
        <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical={location.pathname} /> }
       <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
       <Header type={fixedHeader}/>
         {/* <div id="custom-header" className='roofs-header'> */}
         {content.length>0 ? 
        // <div id="custom-header" className='dome-header' >
        // <div id="custom-header" className='dome-header' style={{backgroundImage: 'url('+baseUrl+'/uploads/content/'+content[0].dome+')',}}>
        <div className='website-page-header'>
            <img src={baseUrl+'/uploads/content/'+content[0].dome} />
            <h1 className="page-title text-aligncenter">Domes</h1>
         </div> :''}
         <div className="site-content global-layout-no-sidebar ">
         <div className="container">
             <div className="inner-wrapper">
                 <div id="primary" className="content-area">
                     <main id="main" className="site-main">
                         <aside className="section">
                             <div className="section-teams">
                                 <div className="container">
                                     <div className="col-grid-12">
                                         <div className="teams-section roofbg-business">
                                             {/* <h2>For Business</h2> */}
                                             <div className="inner-wrapper">
                                             {businessList.map((business)=>{
                                                if(business.image != ""){
                                                    return( 
                                                        <div className="col-grid-3 team-item">
                                                            <ImageCard application='domes' key={business.id} link={business.link} image={business.image} title={business.title} prod_title={business.prod_title}/>
                                                        </div>
                                                    )
                                                }else{ return(randomImage(business, businessList))}
                                            })}
                                             </div>
                                         </div>
                                     </div>
                                     {/* <div className="col-grid-6">
                                         <div className="teams-section roofbg-home">
                                             <h2>For Homes</h2>
                                             <div className="inner-wrapper">
                                                 {homesList.map((homes)=>
                                                     <ImageCard key={homes.id} link={homes.link} image={homes.image} title={homes.title} prod_title={homes.prod_title}/>
                                                 )}
                                             </div>
                                         </div>
                                     </div> */}
                                 </div>
                             </div>
                         </aside>
                     </main>
                 </div>
             </div>
         </div>
         {/* <div className="col-grid-12 other_roofs">
                                       <div className="container">
                                       <div className="teams-section roofbg-other">
                                           <div className="other_cont">
                                                <h2>Others</h2>
                                                <div className="inner-wrapper">
                                                    <div className="section-teams">
                                                    {othersList.map((other)=>
                                                    <div className="col-grid-4 team-item">
                                                        <ImageCard key={other.id} link={other.link} image={other.image} title={other.title} prod_title={other.prod_title}/>
                                                    </div>
                                                    )}
                                                    </div> 
                                                </div>
                                                </div>
                                            </div>
                                        </div>                 
                                    </div> */}
            </div>
            <PageContentBox page='2' content={description.description} />
            <Footer /> 
        </div>
        <div id="btn-whatsapp">
          <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
          </a>
        </div>
     </>
    );
}

export default Domes;