import React, { useEffect, useState, useRef } from "react";
import Footer from "../../components/footer";
import Header from "../../components/header";
import { Spinner } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import axios from "axios";
import "react-multi-carousel/lib/styles.css";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";
import ProductCard from "../../components/productCard";
import SEOHelmet from '../../components/SEOHelmet';
import { useLocation } from "react-router-dom";
import PageContentBox from "../../components/pageContentBox";
import Oops404 from "../../components/Oops404";
import { res_carousel, bannerresp } from "../../utils";
const baseUrl = process.env.REACT_APP_API;

function Product(props) {
  const [product,setProduct] = useState([]);
  const [banner,setBanner] = useState([]);
  const [related_project,setRelated_project] = useState([]);
  const [roofs,setRoofs] = useState([]);
  const [fixedHeader,setFixedHeader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [page404, setPage404] = useState(false);
  const location = useLocation();

  useEffect(()=>{
    setLoading(true);
    // let prod_id = location.state.id;
    const url = window.location.href.split("/");
    var prod_name = url[4];
    prod_name = prod_name.split('-').join(' ');
    prod_name = prod_name.split(':').join('-');
    prod_name = prod_name.split('+').join('/');
    
    axios.get(`${baseUrl}/product/id`, {  params: { id: prod_name },})
      .then((res) => {
        const product = res.data.data;
        if(product.length == 0){
          setPage404(true);
        }else{

          setProduct(product);
          setLoading(false);
          var value = res.data.data[0].image1;
          value = value.toString().split(",");
          const banner = value;
          setBanner(banner);

          axios.get(`${baseUrl}/project/`).then((res) => {
            const project = res.data.data;
            var application = product[0].type;
            var projects = project.filter((project) => project.proj_type == application);
            const related_project = projects;
            setRelated_project(related_project);
          });
        }
       
      });

  }, []);

  const scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      setFixedHeader(true);
    }else{
      setFixedHeader(false);
    }
  };

  const randomImage = (proj, array)=>{
    const random = Math.floor(Math.random() * array.length);
    let randomImg = array[array.length-1].image1;
    if(randomImg != ''){
        return <ProductCard application={proj.proj_type}  id={proj.id} image1={randomImg} title={proj.title} />
    }else{
        randomImage(proj, array);
    }
  }

  return (
    
    <>
    { product.length >0 && <SEOHelmet title={product[0].seo_title} description={product[0].seo_description} canonical={location.pathname} /> }
    { page404  ? <Oops404 /> : 
     <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
    <Header type={fixedHeader}/>
    {product.map((prod, i) => (
      <div className="site-content global-layout-no-sidebar">
        <div id="primary" className="content-area">
          <main id="main" className="site-main product">
            <Carousel
              responsive={bannerresp}
              arrows
              autoPlay
              autoPlaySpeed={3000}
              centerMode={false}
              className=""
              containerClass="container-with-dots"
              dotListClass=""
              draggable
              focusOnSelect={false}
              infinite
              itemClass=""
              keyBoardControl
              minimumTouchDrag={80}
              renderButtonGroupOutside={false}
              renderDotsOutside={false}
            >
            
              {banner.map((prod, i) => (
                <div className="carousel-inner banner">
                  <div className="section no-padding carousel-item">
                    <img
                      className="d-block w-100 banner-img"
                      src={baseUrl+'/uploads/product/images/' + prod}
                      alt="First slide"
                    />
                    <div className="carousel-caption">
                      <h3 className="section-title product">
                        {prod.title}
                      </h3>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
            <aside className="section">
              <div className="section-teams">
                <div className="container">
                  <div className="col-grid-12">
                    <div className="teams-section" style={{paddingTop:'10px'}}>
                      <div className="col-grid-12 service-block-item text-aligncenter">
                        <h1 className="section-title">{prod.title}</h1>
                      </div>
                      <div className="col-grid-12 service-block-item text-alignjustify">
                        <p
                          className="section-description text-alignjustify"
                          dangerouslySetInnerHTML={{
                            __html: prod.description,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
            <div id="content">
              <aside className="section video-bg background-img overlay-enabled">
                <div className="videoplayer">
                  <video
                    src={baseUrl+ '/uploads/product/videos/' + prod.video}
                    muted
                    loop
                    autoPlay
                    id="myVideo"
                  >
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </aside>
              <aside>
                <div className="container product_features">
                  <div className="feature">
                    <h3>Features</h3>
                    <div dangerouslySetInnerHTML={{ __html: prod.features, }} ></div>
                  </div>
                  <div className="feature">
                    <h3>Specification</h3>
                    <div dangerouslySetInnerHTML={{ __html: prod.specification, }} ></div>
                  </div>
                  <div className="feature">
                    <h3>Application</h3>
                    <div dangerouslySetInnerHTML={{ __html: prod.application, }} ></div>
                  </div>
                </div>
              </aside>
              {related_project.length>0 ? 
              <aside className="overlay-enabled">
                <div className="container">
                  <div className="related-products">
                    <h2>Related Projects</h2>
                    <Carousel
                      responsive={res_carousel}
                      arrows
                      autoPlay
                      autoPlaySpeed={3000}
                      centerMode={false}
                      className=""
                      containerClass="container-with-dots"
                      dotListClass=""
                      draggable
                      focusOnSelect={false}
                      infinite
                      itemClass=""
                      keyBoardControl
                      minimumTouchDrag={80}
                      renderButtonGroupOutside={false}
                      renderDotsOutside={false}
                    > 
                    {related_project.map((proj)=>{
                      if(proj.image1 != ""){
                          return( 
                              <ProductCard application={proj.proj_type} id={proj.id} image1={proj.image1} title={proj.title} />
                          )
                      }else{ return(randomImage(proj, related_project))}
                  })}
                    </Carousel>
                  </div>
                </div>
              </aside> : ''}

              <aside
                className="section quote"
                style={{ background: "#E4E4E4", padding: "0px" }}
              >
                <div className="container text-aligncenter">
                  <div className="col-grid-12">
                    <Link
                      className="custom-button button-medium"
                      to="/contact" //style="margin-top:0px;"
                    >
                      Get A Quote
                    </Link>
                  </div>
                </div>
              </aside>
            </div>
          </main>
        </div>
      </div>
    ))}
     { product.length >0 &&  <PageContentBox page='1' content={product[0].content} /> }
    {/* <PageContentBox page='product' content={product[0].content} /> */}
    <Footer />
    <div id="btn-whatsapp">
        <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
        </a>
    </div>
    </div>
    
    }
  </>
  );
}

export default Product;