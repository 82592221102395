import React, {useState} from 'react';
import { Col, Row, Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ShareModal from './shareModal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShareAlt} from '@fortawesome/free-solid-svg-icons'

const baseUrl = process.env.REACT_APP_API;

function BlogCard(props) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const createMarkup = (content)=>{
    return {__html: content};
  }

  let title = props.blog.title.split('-').join(':');
  title = title.split(' ').join('-');
  title = title.split('/').join('+');

  return (
    <>
    <ShareModal show={show} handleClose={handleClose} url={title} />
    {props.type=='main' ?
     <div className='container'>
        <Row className='main-blog'>
          <Col lg={6}>
            <div className='frame'>
              <Image src={baseUrl+'/uploads/blog/'+props.blog.head_image} />
            </div>
          </Col>
          <Col lg={6} className='blog-content'>
            <div className='info'>
              <h2 className='mb-4 pb-4' dangerouslySetInnerHTML={createMarkup(props.blog.title)}></h2>
              <p className='mb-4' dangerouslySetInnerHTML={props.blog.desc_1.length >250 ? createMarkup(props.blog.desc_1.substring(0,250)+'...') : createMarkup(props.blog.desc_1) }></p>
              <Row>
                <Col lg={3} xs={4} ><Link className="custom-button" to={`/blog/${title}`}>Read More</Link></Col>
                <Col lg={7} xs={6} className='text-center' ><p className='time'>{props.blog.formatted_date} . {props.blog.read_time}</p></Col>
                <Col lg={2} xs={2} className='text-right'> <FontAwesomeIcon icon={faShareAlt} onClick={handleShow}/>
                {/* <i class="fa fa-share-alt" aria-hidden="true" onClick={handleShow}></i> */}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
      :<div className={props.active && 'active'}>
        <div className='container'>
          <Row className='sub-blog'>
            <Col lg={5}>
              <div className='frame'>
                <Image src={baseUrl+'/uploads/blog/'+props.blog.head_image}  />
              </div>
            </Col>
            <Col lg={7} className='blog-content'>
              <div className='info'>
                <h2 className='mb-4 pb-4' dangerouslySetInnerHTML={createMarkup(props.blog.title)}></h2>
                <p className='time'>{props.blog.formatted_date} . {props.blog.read_time}</p>
                <Row>
                  <Col lg={3} xs={4}><Link className="custom-button" to={`/blog/${title}`}>Read More</Link></Col>
                  <Col className='text-right'> <FontAwesomeIcon icon={faShareAlt} onClick={handleShow}/></Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
     }
    </>
   
  );
}

export default BlogCard;