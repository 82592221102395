import React from 'react';
import { Link } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API;

function Spotlight(props) {

  const application =  props.application == 1 ? 'roof-and-enclosures' : 'dome';
  let title = props.title.split('-').join(':');
  title = title.split(' ').join('-');
  title = title.split('/').join('+');
  const projimg = `${baseUrl}/uploads/project/images/`;
  return (
    <div className="col-grid-4 featured-page-grid-item">
    <div className="featured-page-grid-wrapper">
      <div className="featured-page-grid-thumb ">
        <img className='spotlight' alt="Recent Post"  height='190px' width='100%' src={projimg + props.image}  />
      </div>
      <div className="featured-page-grid-text-content-wrapper text-aligncenter">
        <div className="featured-page-grid-text-content">
          <div className="proj_detail">
            <h3 className="featured-page-grid-title">  {props.title}</h3>
            <p>{props.location}</p>
          </div>
          <p>
            <Link to={{ pathname: `/${application}/${title}`}} state={{ id: props.id }}  className="custom-button mt0" >  Know More</Link>
          </p>
        </div>
      </div>
    </div>
  </div>
  );
}

export default Spotlight;
