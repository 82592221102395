import React from 'react';
import { Link } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API;

function ImageCard(props) {
   
    let title = props.prod_title != undefined ? props.prod_title.split('-').join(':') : '';
    title = title.split(' ').join('-');
    title = title.split('/').join('+');
    return (
        
            // <Link to={`/${props.application}/${props.link}`}  className="image-hover-zoom">
            <Link to={{ pathname: `/${props.application}/${title}`}} state={{ id: props.link }} className="image-hover-zoom">
            <div className="thumb-summary-wrap box-shadow-block box-border">
                <div className="team-thumb thumb-overlay">
                    
                    <img alt="Team" src={baseUrl+'/uploads/roof/'+props.image} height='230px' width='100%'/>
                </div>
                <div className="team-text-wrap">
                    <h3 className="team-title">{props.title}</h3>
                </div>
            </div>
            </Link>
    );
   
}

export default ImageCard;