import React from 'react';
import { Helmet } from 'react-helmet';
const baseUrl = process.env.REACT_APP_SITE
function SEOHelmet(props) {
  return (
    <>
      { props.title != '' && props.description != '' ?  
        <Helmet>
          <title>{props.title}</title>
          <meta name="description" content={props.description} />
          <link rel="canonical" href={baseUrl+props.canonical} />
        </Helmet>
        : <Helmet>
          <title>Megavent</title>
          <meta name="description" content='Megavent' />
        </Helmet>
        } 
    </>
  );
}

export default SEOHelmet;