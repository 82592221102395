import React from 'react';
import { Link } from 'react-router-dom';
const baseUrl = process.env.REACT_APP_API;
// const imgUrl = "https://megavent.zerosoft.in/api/uploads/project/images/";

function OurworkCard(props) {
  const application =  props.proj_type == 1 ? 'roof-and-enclosures' : 'dome';
  let title = props.title.split('-').join(':');
  title = title.split(' ').join('-');
  title = title.split('/').join('+');
  return (
    <div className={"portfolio-item " + props.proj_type} >
      <div className="item-inner-wrapper">
        <div className="portfolio-thumb-wrap">
          <Link to={{ pathname: `/${application}/${title}`}} state={{ id: props.id }}>
            <img
              src={baseUrl + '/uploads/project/images/'+props.image1}
              alt="Portfolio"
              className="portfolio-thumb"
              height='240px'
              width='100%'
            />
            <div className="overlay"></div>
            <Link
              className="zoom-icon"
              data-gal="prettyPhoto[product-gallery]"
              rel="bookmark"
              to={{ pathname: `/${application}/${title}`}} state={{ id: props.id }}
            >
              Know More
            </Link>
          </Link>
        </div>
        <div className="portfolio-content">
          <h3>{props.title}</h3>
          <p>{props.location}</p>
        </div>
      </div>
    </div>
  );
}

export default OurworkCard;
