import React, { Component } from 'react';
import Logo from "../../../assets/images/logo.webp";
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Container } from 'react-bootstrap';
import $ from 'jquery'; 
import jQuery from 'jquery';
import '../component.css';
import {Form, FormControl, Button} from 'react-bootstrap';
import {Offcanvas} from 'react-bootstrap';
import { createRef } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons'

class Header extends Component {

  constructor(props) {
    super(props);
    this.state = {
        'active':'',
    };
  }

  componentDidMount() {

    this.setState({ active: 'home' });
  }


  onChange = (value)=>{
    this.setState({['active']:value});
  }


    render() {
      const { active } = this.state;
        return (
            <header id="masthead"   className={`site-header overlap-header sticky-enabled ${this.props.type ? "fixed-header" : ""}`}>
            <div className="container">
              <div id="new_mob_menu">
                <>
                {['lg'].map((expand) => ( 
                  <Navbar   expand={expand}>
                    <Container fluid>
                      <Navbar.Brand href="/"> 
                        <div className="site-branding pull-left">
                          <div id="site-identity">
                            <h2 className="site-title">
                              <Link to='/' rel="home" onClick={()=>this.onChange('home')}>
                                <img alt="logo" height="55px" width='100%' src={Logo} />
                              </Link>
                            </h2>
                          </div>
                        </div> 
                      </Navbar.Brand>
                      <Navbar.Toggle aria-controls="responsive-navbar-nav" ><FontAwesomeIcon icon={faBars}/></Navbar.Toggle>
                      <Navbar.Offcanvas  id="responsive-navbar-nav">
                        <Offcanvas.Header closeButton variant="white">
                          <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                            <div id="site-identity">
                                <h2 className="site-title">
                                  <Link to='/' rel="home" onClick={()=>this.onChange('home')}>
                                    <img alt="logo" src={Logo} />
                                  </Link>
                                </h2>
                              </div>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Nav  className="justify-content-end flex-grow-1 pe-3 nav-links1">
                            <Link to='/' onClick={()=>this.onChange('home')} className={active == 'home' ? "" : ""}> Home </Link>
                            <Link to='/about' onClick={()=>this.onChange('about')} className={active == 'about' ? "current-menu-item" : ""}>  About </Link>
                            <Link to='/roof' onClick={()=>this.onChange('roof')} className={`${active == 'roof' ? "current-menu-item" : ""}`}> Roofs & Enclosures </Link>
                            <Link to='/dome' onClick={()=>this.onChange('dome')} className={`${active == 'dome' ? "current-menu-item" : ""}`}>Dome </Link>
                            <Link to='/our-work' onClick={()=>this.onChange('works')} className={`${active == 'works' ? "current-menu-item" : ""}`}>Our Work </Link>
                            <Link to='/faq' onClick={()=>this.onChange('faq')} className={`${active == 'faq' ? "current-menu-item" : ""}`}> FAQs </Link>
                            <Link to='/blog' onClick={()=>this.onChange('blog')} className={`${active == 'blog' ? "current-menu-item" : ""}`}> Blog </Link>
                            <Link to='/contact' onClick={()=>this.onChange('contact')} className={`${active == 'contact' ? "current-menu-item" : ""}`}> Contact Us</Link>
                          </Nav>
                        </Offcanvas.Body>
                      </Navbar.Offcanvas>
                    </Container>
                  </Navbar>
                ))}
                </>
              </div>
            </div>
          </header>
        );
    }
}

export default Header;