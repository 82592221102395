import React, {useState, Component} from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import axios from 'axios';
import $ from 'jquery';
import jQuery from 'jquery';
import { Link } from 'react-router-dom';
import {Tab, Nav, Row, Co, Form} from "react-bootstrap";
import  OurworkCard  from '../../components/ourworkCard';
import SEOHelmet from '../../components/SEOHelmet';
const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;

class OurWork extends Component {
  constructor(props) {
    super(props);
    this.state = {
        project : [],
        application: [],
        content:[],
        seo: '',
        key:'*',
        filter:'*',
        fixedHeader:false
    };
  }

    

  componentDidMount(){

    axios.get(`${baseUrl}/content/our-work`).then((res) => {
      const content = res.data.content;
      const seo = res.data.seo[0];
      this.setState({ content });
      this.setState({ seo });
    })

    axios.get(`${baseUrl}/project/`).then((res) => {
        const project = res.data.data.sort(function(a, b){return b.created_date - a.created_date});
        project.map(proj=>proj['display'] = 'block')
        
        this.setState({ project })
          setTimeout(() => {
          }, 1000);
    })

    axios.get(`${baseUrl}/roofs/`).then((res) => {
        const application = res.data.data;
        this.setState({ application })
          setTimeout(() => {
          }, 1000);
    })
      
  }

  scrollHandler = (event) => {
    event.preventDefault();
    const scrollTop = event.currentTarget.scrollTop;
    if(scrollTop>100){
      this.setState({fixedHeader:true});
    }else{
      this.setState({fixedHeader:false});
    }
  };

  randomImage = (proj, array)=>{
    const random = Math.floor(Math.random() * array.length);
    let randomImg = array[array.length-1].image1;
    if(randomImg != ''){
        return <OurworkCard proj_type={proj.proj_type} image1={randomImg} id={proj.id} location={proj.location} title={proj.title}  />
    }else{
        this.randomImage(proj, array)
    }
  }

  // handleChange = (event) => {

  //   var filter = event.target.value;
  //   console.log(filter,);
  //   axios.get(`${baseUrl}/project/`,{params: {id : filter} }).then((res) => {
  //     const project = res.data.data.sort(function(a, b){return b.created_date - a.created_date});
  //     this.setState({ project })
  //   // setLoading(false)
  //     setTimeout(() => {
  //         // setLoading(false)
  //     }, 1000);
  //   })
  // };

    
  handleChange = (event)=>{
    var filter = event.target.value;
    this.setState({filter});
    if(filter=='*'){
      this.state.project.map(proj => proj.display = 'block');
    }else{
      this.state.project.map(proj => proj.application == filter ? proj.display = 'block' : proj.display = 'none' )
    }
  }

  tabChange = (key) =>{
    this.setState({key});
    this.setState({filter:'*'})
    if(key == '*'){
      this.state.project.map(proj => proj.display = 'block');
    }else{
      this.state.project.map(proj => proj.proj_type == key ? proj.display = 'block' : proj.display = 'none' )
    }
  }   
  render(){
    const { project, application, content, fixedHeader, seo, key, filter} = this.state;
    return (
      <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical='/our-work' /> }
      <div className='scroll-div' onScroll={(e)=>this.scrollHandler(e)}>
      <Header type={fixedHeader}/>
        {/* <div id="custom-header" className="ourWork-header"> */}
        {content.length>0 ? 
        // <div id="custom-header" style={{backgroundImage: 'url('+baseUrl+'/uploads/content/'+content[0].ourwork+')',}}>
        //   <div className="custom-header-content">
        //     <div className="container">
        //       <h1 className="page-title text-aligncenter">Our Work</h1>
        //     </div>
        //   </div>
        // </div>
        <div className='website-page-header'>
            <img src={baseUrl+'/uploads/content/'+content[0].ourwork} />
            <h1 className="page-title text-aligncenter">Our Work</h1>
        </div>
        :''} 
        <div id="content" className="site-content global-layout-no-sidebar our-work">
          <div className="container">
            <div className="inner-wrapper">
              <div id="primary" className="content-area">
                <main id="main" className="site-main">
                  <aside className="section">
                    <div className="section-portfolio">
                      <div className="container">
                        <div className="portfolio-main-wrapper">
                        
                          <Tab.Container id="left-tabs-example"  defaultActiveKey="*" onSelect={(key)=>this.tabChange(key)}>
                            <div className="filter-cont">
                              <div className="project_select">
                                <Nav variant="pills" className="flex-column our-work">
                                <Nav.Item>
                                    <Nav.Link eventKey="*">All</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="1">Retractable Roofs & Enclosures</Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item>
                                    <Nav.Link eventKey="2">Domes</Nav.Link>
                                  </Nav.Item>
                                </Nav>
                              </div>
                              {key!='*' &&
                              <div className="proj_filter">
                                <label for="options">Application:</label>{" "}
                                <select id="options" name='filter' value={filter} onChange={this.handleChange}>
                                  <option value='*' >All</option>
                                  {key=='*' ? application.map((app, i) => <option value={app.id}>{app.title}</option>)
                                  :application.map((app, i) => app.type==key && <option value={app.id}>{app.title}</option>)}
                                </select>
                              </div>}
                            </div>
                            <Tab.Content>
                            <Tab.Pane eventKey="*">
                              <div  id="portfolio"  className="masonry-wrapper portfolio-container row-fluid wow fadeInUp">
                                {project.map((proj) => {
                                  if(proj.display == 'block' )
                                    if(proj.image1 != ""){
                                      return <OurworkCard proj_type={proj.proj_type} image1={proj.image1} id={proj.id} location={proj.location} title={proj.title}  />
                                    }else{
                                      return(this.randomImage(proj, project) )
                                    } 
                                } )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="1">
                              <div  id="portfolio"  className="masonry-wrapper portfolio-container row-fluid wow fadeInUp">
                                {project.map((proj) => {
                                  if(proj.proj_type == "1" && proj.display == 'block' )
                                    if(proj.image1 != ""){
                                      return <OurworkCard proj_type={proj.proj_type} image1={proj.image1} id={proj.id} location={proj.location} title={proj.title}  />
                                    }else{
                                      return(this.randomImage(proj, project) )
                                    } 
                                } )}
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                              <div id="portfolio"  className="masonry-wrapper portfolio-container row-fluid wow fadeInUp">
                                {project.map((proj) => {
                                  if(proj.proj_type == "2" && proj.display == 'block' ){
                                    if(proj.image1 !=""){
                                      return <OurworkCard proj_type={proj.proj_type} image1={proj.image1} id={proj.id} location={proj.location} title={proj.title}  />
                                    }
                                    else{
                                      return this.randomImage(proj, project)
                                    }
                                  }
                                })}
                              </div>
                            </Tab.Pane>
                            </Tab.Content>
                          </Tab.Container>
                        </div>
                      </div>
                    </div>
                  </aside>
                </main>
              </div>
            </div>
          </div>
        </div>
        <aside
                      className="quote" style={{ background: "#E4E4E4", padding:"0px"}}
                    >
                      <div className="container text-aligncenter">
                        <div className="col-grid-12">
                          <Link
                            className="custom-button button-medium"
                            to="/contact" //style="margin-top:0px;"
                          >
                            Get A Quote
                          </Link>
                        </div>
                      </div>
                    </aside>
        <Footer />
     </div>
     <div id="btn-whatsapp">
            <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
            </a>
        </div>
      </>
    );
}
}

export default OurWork;