import React, {useState,useEffect, useRef}from 'react';
import Footer from '../../components/footer';
import Header from '../../components/header';
import icon1 from "../../../assets/images/home/icon1.webp";
import icon2 from "../../../assets/images/home/icon2.webp";
import icon3 from "../../../assets/images/home/icon3.webp";
import icon4 from "../../../assets/images/home/icon4.webp";
import icon5 from "../../../assets/images/home/icon5.webp";
import icon6 from "../../../assets/images/home/icon6.webp";
import icon7 from "../../../assets/images/home/icon7.webp";
import project1 from "../../../assets/images/home/project1.webp";
import project2 from "../../../assets/images/home/project2.webp";
import project3 from "../../../assets/images/home/project3.webp";
import project4 from "../../../assets/images/home/project4.webp";
import product1 from "../../../assets/images/home/products1.webp";
import product2 from "../../../assets/images/home/products2.webp";
import icon1_small from "../../../assets/images/home/icon1_small.webp";
import icon2_small from "../../../assets/images/home/icon2_small.webp";
import icon3_small from "../../../assets/images/home/icon3_small.webp";
import icon4_small from "../../../assets/images/home/icon4_small.webp";
import icon5_small from "../../../assets/images/home/icon5_small.webp";
import icon6_small from "../../../assets/images/home/icon6_small.webp";
import icon7_small from "../../../assets/images/home/icon7_small.webp";
import project1_small from "../../../assets/images/home/project1_small.webp";
import project2_small from "../../../assets/images/home/project2_small.webp";
import project3_small from "../../../assets/images/home/project3_small.webp";
import project4_small from "../../../assets/images/home/project4_small.webp";
import product1_small from "../../../assets/images/home/products1_small.webp";
import product2_small from "../../../assets/images/home/products2_small.webp";
import rating from "../../../assets/images/home/rating.webp";
import video from "../../../assets/video/background.mp4";
import { Carousel,Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../pages.css';
import axios from 'axios'
import {Helmet} from "react-helmet";
import SEOHelmet from '../../components/SEOHelmet';
import PageContentBox from '../../components/pageContentBox';
import { useLocation } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle,} from '@fortawesome/free-solid-svg-icons'

const baseUrl = process.env.REACT_APP_API;
const siteURL = process.env.REACT_APP_SITE;
const bannerUrl = baseUrl+'/uploads/banner/';

const Home = ()=> {
    const [bannerList,setBannerList] = useState([]);
    const [testimonial,setTestimonial] = useState([]);
    const [content, setContent] = useState([]);
    const [seo, setSeo] = useState('');
    const [description, setDescription] = useState('');
    const [videoEvent, setVideoEvent] = useState('');
    const [loading, setLoading] = useState(true);
    const [count, setCount] = useState([1, 2, 3, 4, 5]);
    const [progress, setProgress] = useState(0);
    const [fixedHeader, setFixedHeader] = useState(false);
    const location = useLocation();
    const videoRef = useRef(null);

    useEffect(()=>{
        axios.get(`${baseUrl}/banner/`).then((res) => {
            const banner = res.data.data;
            setBannerList(banner);
            setLoading(false)
        })
        axios.get(`${baseUrl}/testimonial/`).then((res) => {
            const testimonial = res.data.data;
            setTestimonial(testimonial);
        })

        axios.get(`${baseUrl}/content/home`).then((res) => {
            const content = res.data.content;
            const seo = res.data.seo[0];
            const description = res.data.description[0];
            setContent(content);
            setSeo(seo);
            setDescription(description);

        })
       
    }, [])

    const videoStatus = () =>{
        if(videoRef.current.paused){
            videoRef.current.play();
            setVideoEvent('pause')
        }else{
            videoRef.current.pause();
            setVideoEvent('play')
        }
       
    }

    const renderRating = (n, count) =>{
        if(n <= count ){
            return <img src={rating} alt="Megavent" width={35} height={30} />
        }
        
    }

    // This function is triggered when the user scroll
    const scrollHandler = (event) => {
        event.preventDefault();
      const scrollTop = event.currentTarget.scrollTop;
      setProgress(scrollTop);
      if(scrollTop>100){
        setFixedHeader(true);
      }else{
        setFixedHeader(false);
      }
    };



    
    return (
        <>
        { seo!='' && <SEOHelmet title={seo.title} description={seo.description} canonical={location.pathname} /> }
        <div className='scroll-div' onScroll={(e)=>scrollHandler(e)}>
        {loading ? <Spinner animation="grow" variant="danger" /> :
            
            <div>
                <Header type={fixedHeader}/>
                <h1 style={{display:'none'}}>Home</h1>
                <div id="content" className="site-content global-layout-no-sidebar">
                <div className="">
                    <div className="home-content inner-wrapper">
                        <div id="primary" className="content-area">
                            <main id="main" className="site-main">
                                <aside className="section no-padding">
                                    <Carousel className='banner' fade >
                                        {bannerList.map((banner, i)=>
                                            <Carousel.Item key={banner.id} interval={3000}>
                                            {/* <img
                                            className="d-block w-100"
                                            src={baseUrl+'/uploads/banner/'+banner.image}
                                            alt="First slide"
                                            /> */}
                                            <img className="d-block w-100" src={bannerUrl+banner.image} srcSet={`${bannerUrl+banner.image_small} 480w, ${bannerUrl+banner.image} 1280w`} sizes="50vw" alt={`banner${i}`}></img>
                                            <Carousel.Caption >
                                                <div className='home-banner-overlay'>
                                                    <h3><a href={banner.button_link}> {banner.title} </a></h3>
                                                    <p> {banner.description} </p>
                                                </div>
                                                <div className="slider-buttons">
                                                    <a className="custom-button button-medium" href={banner.button_link}> {banner.button_text}</a>
                                                </div>
                                            </Carousel.Caption>
                                            </Carousel.Item>
                                        )}
                                    </Carousel>
                                </aside>
                                <aside className="section benifits">
                                    <div className="section-services service-layout-1">
                                        <div className="container">
                                            <div className="service-block-list">
                                                <div className="inner-wrapper">
                                                    <div className="col-grid-12 service-block-item text-aligncenter">
                                                        <h2 className="section-title">Benefits of retractable roofs</h2>
                                                    </div>
                                                    <div className="benefits row">
                                                        <div className="col-6 col-lg-3 service-block-item benefits_block">
                                                            <div className="service-block-inner box-shadow-block">
                                                                <div className='service-icon'> 
                                                                    <img  src={icon1} srcSet={`${icon1_small} 480w, ${icon1} 1280w`} sizes="50vw" alt="icon1" width={90} height={90}></img>
                                                                    {/* <img  src={icon1} alt="icon1" width={90} height={90} /> */}
                                                                </div>
                                                                <div className="service-block-inner-content">
                                                                    <h3 className="service-item-title">Mersmerising Views</h3>
                                                                    <div className="service-block-item-excerpt">
                                                                        <p>Be it dawn or dusk, get the best ticket to breathtaking views with upto 100% opening.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-lg-3 service-block-item benefits_block">
                                                            <div className="service-block-inner box-shadow-block">
                                                                <div className='service-icon'> 
                                                                <img  src={icon2} srcSet={`${icon2_small} 480w, ${icon2} 1280w`} sizes="50vw" alt="icon2" width={90} height={90}></img>
                                                                {/* <img  src={icon2} alt="icon2" width={90} height={90} /> */}
                                                                </div>
                                                                <div className="service-block-inner-content">
                                                                    <h3 className="service-item-title">Stress-free Storms</h3>
                                                                    <div className="service-block-item-excerpt">
                                                                        <p>State of the art inbuilt guttering system that handles heavy downpours.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-lg-3 service-block-item benefits_block">
                                                            <div className="service-block-inner box-shadow-block">
                                                                <div className='service-icon'> 
                                                                <img  src={icon3} srcSet={`${icon3_small} 480w, ${icon3} 1280w`} sizes="50vw" alt="icon3" width={90} height={90}></img>
                                                                {/* <img  src={icon3} alt="icon3" width={90} height={90} />    */}
                                                                </div>
                                                                <div className="service-block-inner-content">
                                                                    <h3 className="service-item-title">Lap of Luxury</h3>
                                                                    <div className="service-block-item-excerpt">
                                                                        <p>Transform your outdoor areas into functional and engaging spaces.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6 col-lg-3 service-block-item benefits_block">
                                                            <div className="service-block-inner box-shadow-block">
                                                            <div className='service-icon'> 
                                                            <img  src={icon4} srcSet={`${icon4_small} 480w, ${icon4} 1280w`} sizes="50vw" alt="icon4" width={90} height={90}></img>
                                                            {/* <img  src={icon4} alt="icon4" width={90} height={90} /> */}
                                                            </div>
                                                                <div className="service-block-inner-content">
                                                                    <h3 className="service-item-title">No Fussing</h3>
                                                                    <div className="service-block-item-excerpt">
                                                                        <p>Obtain Municipal permission easily since the structures can be relocated.</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <div className="col-grid-12 service-block-item text-aligncenter">
                                    <h2 className="section-title">Why Choose Megavent?</h2>
                                </div>
                                <aside className="section dark-background">
                                    <div className="section-counter">
                                        <div className="container">
                                            <div className="counter-section">
                                                <div className="inner-wrapper">
                                                    <div className="col-grid-3 counter-item">
                                                        <div className="thumb-summary-wrap">
                                                            <div className="counter-icon">
                                                                <img  src={icon5} srcSet={`${icon5_small} 480w, ${icon5} 1280w`} sizes="50vw" alt="icon4" width={50} height={50}></img>
                                                                {/* <img src={icon5} alt="icon5" width={50} height={50} /> */}
                                                                </div>
                                                            <div className="counter-content">
                                                                <h3>ISO 9001:2015</h3>
                                                                <p>Certified</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-grid-3 counter-item">
                                                        <div className="thumb-summary-wrap">
                                                            <div className="counter-icon">
                                                            <img  src={icon5} srcSet={`${icon5_small} 480w, ${icon5} 1280w`} sizes="50vw" alt="icon5" width={50} height={50}></img>
                                                                {/* <img src={icon6} alt="icon6"  width={50} height={50}/> */}
                                                                </div>
                                                            <div className="counter-content">
                                                                <h3>15 years warranty</h3>
                                                                <p>on powder coating</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-grid-3 counter-item">
                                                        <div className="thumb-summary-wrap">
                                                            <div className="counter-icon">
                                                            <img  src={icon6} srcSet={`${icon6_small} 480w, ${icon6} 1280w`} sizes="50vw" alt="icon6" width={50} height={50}></img>
                                                                {/* <img src={icon6} alt="icon8" width={50} height={50}/> */}
                                                                </div>
                                                            <div className="counter-content">
                                                                <h3>10 years warranty</h3>
                                                                <p>on polycarbonate sheets</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-grid-3 counter-item">
                                                        <div className="thumb-summary-wrap">
                                                            <div className="counter-icon">
                                                            <img  src={icon7} srcSet={`${icon7_small} 480w, ${icon7} 1280w`} sizes="50vw" alt="icon7" width={50} height={50}></img>
                                                                {/* <img src={icon7} alt="icon7" width={50} height={50} /> */}
                                                                </div>
                                                            <div className="counter-content">
                                                                <h3>Custom made</h3>
                                                                <p>for your space</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <aside className="section video-bg background-img overlay-enabled">
                                    <div className='videoplayer home'>
                                        <video src={video} ref={videoRef} muted loop autoPlay id="myVideo">
                                            Your browser does not support HTML5 video.
                                        </video>
                                    </div>
                                    {/* <div className="player"> 
                                        <iframe className="responsive-iframe" src={video}  ref={videoRef} ></iframe>
                                    </div> */}
                                    

                                    <div className="container">
                                        <div className='content row'>
                                            <div className="col-md-6 col-sm-8">
                                                <div className="featured-page-section">
                                                    <ul className="mylist">
                                                        <li><FontAwesomeIcon icon={faCheckCircle}/> Water tight roofs and enclosures</li>
                                                        <li><FontAwesomeIcon icon={faCheckCircle}/>Strong and durable yet lightweight</li>
                                                        <li><FontAwesomeIcon icon={faCheckCircle}/>Customisable with palette of colors</li>
                                                        <li><FontAwesomeIcon icon={faCheckCircle}/>Easily linked to home automation system</li>
                                                        <li><FontAwesomeIcon icon={faCheckCircle}/>Double sided UV coated polycarbonate sheets</li>
                                                    </ul>
                                                </div>
                                            </div>
                                            {/* <div className="col-6">
                                                <br/><br/>
                                                <a onClick={videoStatus} className="media-zoom-icon" data-gal="prettyPhoto" rel="bookmark">
                                                   {videoEvent=='play' ?  <i className="fas fa-play" ></i> : <i className="fas fa-pause" ></i> }
                                                    </a>
                                            </div> */}
                                        </div>
                                    
                                    </div>
                                </aside>
                                <aside className="section">
                                    <div className="section-recent-work">
                                        <div className="container">
                                            <div className="section-title-wrap text-alignleft">
                                                <h2 className="section-title text-aligncenter">Our Products</h2>
                                                <p className="text-alignleft">Megavent houses a wide range of products to fit every need of the customer. Take a look at our retractable roofs & enclosures and dome for both businesss and homes.</p>
                                            </div>
                                            <div className="inner-wrapper">
                                                <div className="recent-work-section">
                                                    <div className="recent-work-block main-block">
                                                        <div className="recent-work-inner">
                                                            <div className="block-container">
                                                                <div className="thumb-overlay"></div>
                                                                <div className="thumb-holder">
                                                                    {/* <img alt="Roofs and Enclosures" src={product1} /> */}
                                                                    <img className='w-100' src={product1} srcSet={`${product1_small} 480w, ${product1} 1280w`} sizes="50vw" alt="Roofs and Enclosures" width={500} height={220}></img>
                                                                    {/* <picture>
                                                                        <source media="(max-width:650px)" srcSet={product1} width={500} height={220}/>
                                                                        <img src={product1} alt="Roofs and Enclosures" />
                                                                    </picture> */}
                                                                </div>
                                                                <div className="recent-work-button">
                                                                    <div className="vmiddle-holder">
                                                                        <div className="vmiddle">
                                                                            <Link className="custom-button" to={'/roof'}>Roofs and Enclosures</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h2 className='d-block d-sm-none sm-title'>
                                                            Roofs & Enclosures <Link to={'/roof'} >KNOW MORE </Link>
                                                        </h2>
                                                    </div>
                                                    <div className="recent-work-block main-block">
                                                        <div className="recent-work-inner">
                                                            <div className="block-container">
                                                                <div className="thumb-overlay"></div>
                                                                <div className="thumb-holder">
                                                                    {/* <picture>
                                                                        <source media="(max-width:650px)" srcSet={product2} width={500} height={220}/>
                                                                        <img src={product2} alt="Domes" />
                                                                    </picture> */}
                                                                    <img className='w-100'  src={product2} srcSet={`${product2_small} 480w, ${product2} 1280w`} sizes="50vw" alt="Domes" width={500} height={220}></img>
                                                                    {/* <img alt="works" src={product2} /> */}
                                                                </div>
                                                                <div className="recent-work-button">
                                                                    <div className="vmiddle-holder">
                                                                        <div className="vmiddle">
                                                                            <Link className="custom-button" to={'/dome'}>Domes</Link>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <h2 className='d-block d-sm-none sm-title'>
                                                            Domes <Link to={'/dome'} >KNOW MORE</Link>
                                                        </h2>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <aside className="section" style={{backgroundColor:'#e4e4e4'}}>
                                    <div className="section-skill-bar">
                                        <div className="container">
                                            <div className="inner-wrapper row ">
                                                <div className="col-md-3">
                                                    <h3 className="blue">Four Steps to Every Project</h3>
                                                    <p  className="four-steps">We build premium, custom-designed retractable roofs and enclosures. To create your perfect space, it takes thorough assessment, design and development process.</p>
                                                </div>
                                                <div  className="col-md-4 offset-xl-1">
                                                    <div className='row icons-bar'>
                                                        <div className="col-6 text-aligncenter">
                                                            <p className="fourproject">
                                                                <picture>
                                                                    <source media="(max-width:720px)" srcSet={project1} width={60} height={60}/>
                                                                    <img src={project1} alt="project1" width={90} height={90} />
                                                                </picture>
                                                                {/* <img src={project1} alt="Megavent" /> */}
                                                            </p>
                                                            <h6>Concept &amp; design</h6>
                                                        </div>
                                                        <div className="col-6 text-aligncenter">
                                                            <p className="fourproject">
                                                                <picture>
                                                                    <source media="(max-width:720px)" srcSet={project2} width={60} height={60}/>
                                                                    <img src={project2} alt="project2" width={90} height={90} />
                                                                </picture>
                                                            {/* <img src={project2} alt="Megavent" /> */}
                                                            </p>
                                                            <h6>Order</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                               <div  className="col-md-4">
                                                    <div className='row icons-bar'>
                                                        <div className="col-6 text-aligncenter">
                                                            <p className="fourproject">
                                                                <picture>
                                                                    <source media="(max-width:720px)" srcSet={project3} width={60} height={60}/>
                                                                    <img src={project3} alt="project3" width={90} height={90} />
                                                                </picture>
                                                                {/* <img src={project3} alt="Megavent" /> */}
                                                                </p>
                                                            <h6>Site Prep</h6>
                                                        </div>
                                                        <div className="col-6 text-aligncenter">
                                                            <p className="fourproject">
                                                                <picture>
                                                                    <source media="(max-width:720px)" srcSet={project4} width={60} height={60}/>
                                                                    <img src={project4} alt="project3" width={90} height={90} />
                                                                </picture>
                                                                {/* <img src={project4} alt="Megavent" />*/}
                                                            </p> 
                                                            <h6>Installation</h6>
                                                        </div>
                                                    </div>                                               
                                               </div>
                                               
                                            </div>
                                        </div>
                                    </div>
                                </aside>
                                <aside className="section">
                                {content.map(item=>
                                    <div className="container brochure text-aligncenter">
                                        <div className="col-grid-12 ">
                                        {/* <a className="custom-button button-medium" target="_blank" to={item.brouchure_link} style={{backgroundColor: '#096AA2 !important',color: '#fff !important'}} >Download Brochure</a> */}
                                            <a className="custom-button button-medium" target="_blank" href={item.brouchure_link} style={{margin:'0px', backgroundColor:'#096AA2 !important', color:'#096AA2 !important'}}>Download Brochure</a>
                                       
                                        </div>
                                    </div>
                                     )}
                                </aside>
                                {content.map(item=>
                            <aside className="cta-background background-img overlay-enabled" style={{ background: `url(${baseUrl+'/uploads/content/'+item.testimonial}) no-repeat`, backgroundPosition: "top center", backgroundSize: "cover", minHeight:'550px'}}>
                                    <div className="call-to-action-inner-wrapper section-call-to-action cta-fluid ">
                                        <div className="container">
                                    
                                            <div className="col-grid-2"></div>
                                            <div className="col-grid-8">
                                                <Carousel className='testimonial'>
                                                    {testimonial.map(item=>                                                    
                                                        <Carousel.Item key={item.id} >
                                                            <Carousel.Caption >
                                                                <div className="testimonial_text">
                                                                    {count.map(n => renderRating(n, item.rating))}
                                                                   
                                                                    <p className='d-none d-sm-block'> {item.description} </p>
                                                                    <p className='d-block d-sm-none'> {item.description.length > 800 ? item.description.substring(0,800)+'...': item.description } </p>
                                                                    <h5>- {item.location} </h5>
                                                                </div>
                                                            </Carousel.Caption>
                                                        </Carousel.Item>
                                                    )}
                                                </Carousel>
                                            </div>
                                            <div className="col-grid-2"></div>
                                        </div>
                                    </div>
                                </aside>
                                )}
                            </main>
                        </div>
                    </div>
                </div>
                </div>
                <PageContentBox page='1' content={description.description} />
                <Footer />
               
            </div>
        }
         </div>
        <div id="btn-whatsapp">
            <a title="Go Top" className="scrollup" target="_blank" href="https://wa.me/message/OE4RSQO3BOWQI1">
            </a>
        </div>
        </>
    );
}

export default Home;